import "scss/index.scss";

import { plugin_registry } from 'nk-plugin-registry';
// import your plugins here.

import "components/audio_player";
import "components/page-header";

import "citation/js/citation";
import "foldable_section/js/foldable_section";
import "image_section/js/image_gallery";
import "sponsor_grid/js/sponsor_grid";
import "station_map/js/station_map";

plugin_registry.init();

