import "../scss/station_map.scss";

import {plugin_registry, PluginBase} from "nk-plugin-registry";

import L from "leaflet";
import 'leaflet/dist/leaflet.css';

import MAP_PIN_ICON from 'station_map/assets/map_pin.svg';


const MAP_URL = window.MAP_URL || 'https://angstzonenundschutzraeume.de/tiles/{z}/{x}/{y}.png'


@plugin_registry.register('StationMap')
class StationMap extends PluginBase {
  constructor($node) {
    super($node);
    this.$node = $node;

    this._$map = $node.querySelector('[data-js-select="map"]');
    this._config = JSON.parse($node.querySelector('[data-js-select="config"]').innerText);
    this._center = this._convert_station_location(this._config.center);
    this._markers = [];
  }

  loaded($node) {
    super.loaded($node);
    this._map = L.map(this._$map, {
      attributionControl: false,
      zoomControl: true,
      fullscreenControl: false,
      touchZoom: false,
      scrollWheelZoom: false,
    }).setView(this._center, this._config.zoom)

    this._tile_layer = L.tileLayer(MAP_URL, {
      attribution: '&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors',
    }).addTo(this._map);

    this._map_pin_icon = L.icon({
      iconUrl: MAP_PIN_ICON,
      iconSize: [66, 92],
      iconAnchor: [33, 92],
      popupAnchor: [0, -50]
    });


    this._add_stations();
  }

  disconnect($node) {
    super.disconnect($node);
    this._map = null;
    this._tile_layer = null;
    this.$node = null;
  }

  _convert_station_location = (location_string) => (location_string.split(',').map((a) => (parseFloat(a))))

  _add_stations = () => {
    const stations = this._config.stations;
    this._markers = [];

    for (const station of stations) {
      const marker = L.marker(
        this._convert_station_location(station.location),
        {
          icon: this._map_pin_icon,
          alt: station.name,
          title: station.name,
          riseOnHover: true,
        }
      ).addTo(this._map);
      this._markers.push(marker);
      marker.bindPopup(station.short_name);
      marker.on('mouseover', function (e) {
        this.openPopup();
      });
      marker.on('mouseout', function (e) {
        this.closePopup();
      })
      marker.on('click', () => {
        window.location = station.url
      });
    }
    const pin_group = new L.featureGroup(this._markers);
    this._map.fitBounds(pin_group.getBounds());
  }
}
